import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Hero from '../components/Hero'
import Entries from '../components/Entries'
import RenderContent from '../components/RenderContent'
import Article, { Title, Preamble } from '../components/Article'

import imageHelper from '../helpers/image-helper'
import getVesselsV2 from '../helpers/get-vessels-v2'

export default class ListTemplate extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  state = {
    vessels: null,
  }

  async componentDidMount () {
    const vessels = await getVesselsV2()

    if (vessels.length > 0) {
      this.setState({ vessels })
    }
  }

  render () {
    const { vessels } = this.state
    const { data } = this.props

    if (!data.ievv) {
      return <p>Something went wrong...</p>
    }

    const { title = null, preamble, content, pageId } = data.ievv

    const pages = data.pages.edges
      .filter(({ node }) => node.parentpage_id === pageId)
      .map(({ node }) => {
        const getImage = imageHelper({ images: data.images, ievv: node })
        const image = node.heroImage ? getImage(node.heroImage) : null
        const vessel =
          vessels && vessels.find(item => `${item.imo}` === node.vessel.imo)

        return {
          image,
          to: node.path,
          title: node.title || null,
          description: (vessel && vessel.trade) || '',
          imo: node.vessel.imo,
        }
      })

    const hasContent = content && content.length > 0

    const coordinates = vessels
      ? {
        coordinates: vessels.map(({ name, longitude, latitude }) => ({
          title: name,
          position: [Number(longitude), Number(latitude)],
        })),
      }
      : {
        centerPos: [4, 58],
        zoom: 3.7,
      }

    return (
      <>
        <Hero zoom={3.7} {...coordinates} map />

        <Article center>
          <Title level="1">{title}</Title>
          {preamble && <Preamble>{preamble}</Preamble>}
        </Article>

        <Entries items={pages} type="image" />

        {hasContent && (
          <Article>
            <RenderContent data={data} />
          </Article>
        )}
      </>
    )
  }
}

export const query = graphql`
  query($pageId: String!) {
    ievv(id: { eq: $pageId }) {
      ...ArticleData
    }
    ...GetImages
    pages: allIevv(sort: { fields: [order], order: [ASC] }) {
      edges {
        node {
          title
          preamble
          path
          parentpage_id
          vessel {
            imo
          }
          heroImage
          images {
            id
            image {
              name
            }
          }
        }
      }
    }
  }
`
